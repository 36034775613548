import React, { useState, useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import { noop } from 'utils/helper';
import MenuItem from 'Components/Atoms/MenuItem';
import {
  Settings,
  Help,
  Language,
  Brightness6,
  ArrowDropDown,
  FormatSize,
  Lock,
  VolumeUpOutlined,
  VolumeOffOutlined,
} from '../../../../../Components/Atoms/Icons';
import Button from 'Components/Atoms/Button';
import Typography from 'Components/Atoms/Typography';
import SettingsDropdownLanguages from './SettingsDropdownLanguages';
import Popover from 'Components/Atoms/Popover';
import { UserData } from 'utils/types';
import { useIntercom } from 'react-use-intercom';
import { ButtonGroup } from '@material-ui/core';
import { ThemeContext } from 'Contexts/ThemeContext';
import { RestaurantContext } from 'Contexts/RestaurantContext';
// import { deleteTokenOnLogout } from 'serviceWorkerRegistration';
import PasswordModal from './PasswordModal';
import useInterval from 'CustomHooks/useInterval';
import { useMediaQuery } from '@material-ui/core';
import MobileModal from 'App/Mobile/Components/MobileModal';
import MobilePasswordModal from './MobilePasswordModal';
import SignatureDropdown from 'Components/Organisms/SignatureDropdown';
import useLocalStorageState from 'CustomHooks/useLocalStorageState';
import useRestaurant from 'CustomHooks/useRestaurant';
import useAuth from 'CustomHooks/useAuth';

export type SettingsDropdownOptionsProps = {
  user: UserData;
  language: string;
  darkMode: boolean;
  setdarkMode: React.Dispatch<React.SetStateAction<boolean>>;
  languages: { id: string; label: string }[];
  onLanguageChange: (lang: string) => void;
  onLogout: () => void;
  goToSettings: () => void;
};

const SettingsDropdownOptions = ({
  user,
  darkMode,
  setdarkMode,
  onLanguageChange = noop,
  languages,
  language,
  onLogout = noop,
  goToSettings = noop,
}: SettingsDropdownOptionsProps) => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const [anchorEl, setanchorEl] = useState<null | HTMLElement>(null);
  const [loadingSignOut, setloadingSignOut] = useState<boolean>(false);
  const [mobileLanguagesOpen, setMobileLanguagesOpen] =
    useState<boolean>(false);
  const [changePasswordModal, setChangePasswordModal] =
    useState<boolean>(false);

  const { changeFontSize } = useContext(ThemeContext);

  const { restaurantId } = useRestaurant();

  const [openPasswordModal, setOpenPasswordModal] = useState<boolean>(false);

  const handleLanguageChange = (lang: string) => {
    onLanguageChange(lang);
    setanchorEl(null);
  };

  const [interval, updateInterval] = useInterval();

  const alarm = useMemo(() => {
    return sessionStorage.getItem('disableAlarm') !== 'true';
  }, [interval]);

  const toggleAlarm = () => {
    if (sessionStorage.getItem('disableAlarm') === 'true') {
      sessionStorage.setItem('disableAlarm', 'false');
    } else {
      sessionStorage.setItem('disableAlarm', 'true');
    }
    updateInterval();
  };

  const handleLogout = async (e: any) => {
    e.stopPropagation();
    setloadingSignOut(true);
    try {
      // await deleteTokenOnLogout(user.uid);
    } catch (error) {}
    onLogout();
    setloadingSignOut(false);
  };

  const [global, setglobal] = useLocalStorageState<string | null>(
    `${restaurantId}-globalSignature`,
    null
  );

  // const { boot, show } = useIntercom();

  return (
    <Box
      width={!isMobile ? 'fit-content' : '100%'}
      style={{
        minWidth: 200,
        backgroundColor: !isMobile ? '' : 'var(--color-paper)',
        borderRadius: !isMobile ? 0 : 6,
        border: '1px solid var(--color-border)',
        maxHeight: isMobile ? '500px' : 'auto',
      }}
    >
      {changePasswordModal === true && (
        <MobileModal
          title="Change password"
          titleTranslation="settings"
          isOpen={changePasswordModal}
          onClose={() => setChangePasswordModal(false)}
        >
          <MobilePasswordModal
            open={changePasswordModal}
            onClose={() => setChangePasswordModal(false)}
          />
        </MobileModal>
      )}
      {user?.role === 'admin' && (
        <MenuItem
          onClick={(e: any) => {
            e.stopPropagation();
            goToSettings();
          }}
        >
          <Settings style={{ color: 'var(--color-text-subdued)' }} />
          <Typography
            translation="dashboard"
            variant="text-3"
            style={{ margin: 8 }}
          >
            Settings
          </Typography>
        </MenuItem>
      )}
      <MenuItem
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
        onClick={(e: any) => {
          e.stopPropagation();
          setanchorEl((x) => (!x ? e.currentTarget : null));
          setMobileLanguagesOpen(!mobileLanguagesOpen);
        }}
      >
        <div
          style={{ display: 'flex', alignItems: 'center', width: '100%' }}
          // onClick={(e: any) => {
          //   e.stopPropagation();
          //   // setanchorEl((x) => (!x ? e.currentTarget : null));
          //   setMobileLanguagesOpen(!mobileLanguagesOpen);
          // }}
        >
          <Language style={{ color: 'var(--color-text-subdued)' }} />
          <Typography variant="text-3" style={{ margin: 8 }}>
            Language
          </Typography>
        </div>
        <ArrowDropDown
          style={{
            fill: 'var(--color-text-subdued)',
            right: 5,
            top: 5,
          }}
        />
      </MenuItem>
      {isMobile && mobileLanguagesOpen && (
        <>
          {languages.map((l) => (
            <MenuItem
              key={l.id}
              selected={language === l.id}
              onClick={() => {
                onLanguageChange(l.id);
                setMobileLanguagesOpen(false);
              }}
            >
              {l.label}
            </MenuItem>
          ))}
        </>
      )}
      {!isMobile && (
        <Popover
          anchorEl={anchorEl}
          open={!!anchorEl}
          onClose={() => setanchorEl(null)}
          placement="bottom"
          padding={0}
          margin={5}
          style={{ width: '95%' }}
        >
          <SettingsDropdownLanguages
            {...{ language, onLanguageChange: handleLanguageChange, languages }}
          />
        </Popover>
      )}
      <MenuItem
        onClick={() => {
          // if (!isMobile) {
          //   boot();
          //   show();
          // }
          if (isMobile) {
            let intercomLauncher = document.getElementsByClassName(
              'intercom-lightweight-app-launcher'
            )?.[0];

            if (
              intercomLauncher &&
              intercomLauncher?.classList.contains('show')
            ) {
              intercomLauncher?.classList.remove('show');
            } else {
              intercomLauncher?.classList.add('show');
            }
          }
        }}
      >
        <Help style={{ color: 'var(--color-text-subdued)' }} />
        <Typography variant="text-3" style={{ margin: 8 }}>
          Help
        </Typography>
      </MenuItem>
      <MenuItem noClick>
        <FormatSize style={{ color: 'var(--color-text-subdued)' }} />
        <Typography variant="text-3" style={{ margin: 8 }}>
          Font Size
        </Typography>
        <ButtonGroup>
          <Button variant="outlined" onClick={changeFontSize('remove')}>
            -
          </Button>
          <Button variant="outlined" onClick={changeFontSize('add')}>
            +
          </Button>
        </ButtonGroup>
      </MenuItem>
      {user.provider === 'password' && (
        <>
          <MenuItem
            onClick={() =>
              !isMobile
                ? setOpenPasswordModal(true)
                : setChangePasswordModal(true)
            }
          >
            <Lock style={{ color: 'var(--color-text-subdued)' }} />
            <Typography
              variant="text-3"
              style={{ margin: 8 }}
              translation="auth"
            >
              Change password
            </Typography>
          </MenuItem>
          {openPasswordModal && (
            <PasswordModal
              open={openPasswordModal}
              onClose={() => setOpenPasswordModal(false)}
            />
          )}
        </>
      )}
      <MenuItem
        style={{ borderBottom: 'none' }}
        onClick={(e: any) => {
          e.stopPropagation();
          toggleAlarm();
        }}
      >
        {alarm ? (
          <VolumeUpOutlined style={{ color: 'var(--color-text-subdued)' }} />
        ) : (
          <VolumeOffOutlined style={{ color: 'var(--color-text-subdued)' }} />
        )}
        <Typography
          variant="text-3"
          style={{ margin: 8 }}
          translationKey="common_orderAlert"
        >
          Order Alert: {{ alarm: alarm ? 'Off' : 'On' }}
        </Typography>
      </MenuItem>
      <MenuItem
        style={{ borderBottom: 'none' }}
        onClick={(e: any) => {
          e.stopPropagation();
          setdarkMode((oV) => !oV);
        }}
      >
        <Brightness6 style={{ color: 'var(--color-text-subdued)' }} />
        <Typography variant="text-3" style={{ margin: 8 }} translation="common">
          Dark Mode: {darkMode ? 'Off' : 'On'}
        </Typography>
      </MenuItem>
      <Box style={{ padding: 8 }}>
        <SignatureDropdown value={global} onChange={setglobal} global />
      </Box>
      <Box padding={8}>
        <Button
          loading={loadingSignOut}
          fullWidth
          variant="outlined"
          color="error"
          style={{ borderColor: 'var(--color-critical)' }}
          onClick={handleLogout}
          translation="common"
        >
          Logout
        </Button>
        <small style={{ opacity: 0.5 }}>
          {user.email} ({user.displayName}) 0.4.169
        </small>
      </Box>
    </Box>
  );
};

export default SettingsDropdownOptions;

import React, { useEffect, useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import useRestaurant from 'CustomHooks/useRestaurant';
import Modal from 'Components/Molecules/Modal';
import SwitchBox from 'Components/Atoms/Switch';
import TextField from 'Components/Atoms/TextField';
import { IconButton } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Delete } from '@material-ui/icons';
import Typography from 'Components/Atoms/Typography';
import Button from 'Components/Atoms/Button';
import { FieldValue } from 'utils/firebase';

function changePositionInArray<T = any>(
  arr: T[],
  index: number,
  newIndex: number
) {
  return arr.map((x, i, arr) => {
    if (i === index) return arr[newIndex];
    if (i === newIndex) return arr[index];
    return x;
  });
}

export type ExperimentalFlagsProps = {};
export type ExperimentalFlags = {
  allowAllTableCombinations?: boolean | undefined;
  hideTableSizeWarning?: boolean | undefined;
  allowMultipleSpaces?: boolean | undefined;
  emails?: string;
  disableSecurityForAdmins?: boolean;

  // gilden im zims
  disableRelocateMessage?: boolean;
  hideWalkIns?: boolean;
  truncateTableNames?: boolean;
  truncateComments?: boolean;
  customFieldBeingLate?: boolean;
  hideToasts?: boolean;

  // Meissl & Schadn
  showGuestsInTimeEvenOnLargeFloorplans?: boolean;
  disableShiftCommentAtBottom?: boolean;
  phoneDefaultCountry?: string; // default is de
  editGuestComment?: boolean;
  hideTimeOfCurrentReservation?: boolean;
  allowMultipleStati?: boolean;
  currentTableStati?: {
    title: string;
    color: string;
    chairColor: string;
    textColor: string;
    icon: string;
  }[];
  disabledCustomFields?: string;
  sidebarWidth?: string;
  sidebarFontSize?: string;
};

const ExperimentalFlags: React.FC<ExperimentalFlagsProps> = ({}) => {
  const { experimentalFlags } = useRestaurant();

  const [open, setopen] = useState(false);

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'e' && e.ctrlKey) {
        e.preventDefault();
        setopen(true);
      }
    }

    if (window) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      if (window) {
        window.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);

  return (
    <Modal
      open={open}
      onClose={() => setopen(false)}
      title="Experimental Flags"
      maxWidth="xs"
      fullWidth
    >
      <SwitchBox
        checked={!!experimentalFlags?.data?.allowAllTableCombinations}
        onChange={(_, allowAllTableCombinations) => {
          experimentalFlags.ref.set(
            {
              allowAllTableCombinations,
            },
            { merge: true }
          );
        }}
        label="Allow All Table Combinations"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.hideTableSizeWarning}
        onChange={(_, hideTableSizeWarning) => {
          experimentalFlags.ref.set(
            {
              hideTableSizeWarning,
            },
            { merge: true }
          );
        }}
        label="Hide Table Size Warning"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.hideToasts}
        onChange={(_, hideToasts) => {
          experimentalFlags.ref.set(
            {
              hideToasts,
            },
            { merge: true }
          );
        }}
        label="Hide Toasts (Rückgängig machen)"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.allowMultipleSpaces}
        onChange={(_, allowMultipleSpaces) => {
          experimentalFlags.ref.set(
            {
              allowMultipleSpaces,
            },
            { merge: true }
          );
        }}
        label="Allow Multiple Spaces"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.disableSecurityForAdmins}
        onChange={(_, disableSecurityForAdmins) => {
          experimentalFlags.ref.set(
            {
              disableSecurityForAdmins,
            },
            { merge: true }
          );
        }}
        label="Disable Security Plus For Admins"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.disableRelocateMessage}
        onChange={(_, disableRelocateMessage) => {
          experimentalFlags.ref.set(
            {
              disableRelocateMessage,
            },
            { merge: true }
          );
        }}
        label="Disable Relocate Message"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.hideWalkIns}
        onChange={(_, hideWalkIns) => {
          experimentalFlags.ref.set(
            {
              hideWalkIns,
            },
            { merge: true }
          );
        }}
        label="Hide Walk Ins"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.truncateTableNames}
        onChange={(_, truncateTableNames) => {
          experimentalFlags.ref.set(
            {
              truncateTableNames,
            },
            { merge: true }
          );
        }}
        label="Truncate Table Names"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.truncateComments}
        onChange={(_, truncateComments) => {
          experimentalFlags.ref.set(
            {
              truncateComments,
            },
            { merge: true }
          );
        }}
        label="Truncate Comments"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.customFieldBeingLate}
        onChange={(_, customFieldBeingLate) => {
          experimentalFlags.ref.set(
            {
              customFieldBeingLate,
            },
            { merge: true }
          );
        }}
        label="Custom Field Being Late"
      />

      <SwitchBox
        checked={
          !!experimentalFlags?.data?.showGuestsInTimeEvenOnLargeFloorplans
        }
        onChange={(_, showGuestsInTimeEvenOnLargeFloorplans) => {
          experimentalFlags.ref.set(
            {
              showGuestsInTimeEvenOnLargeFloorplans,
            },
            { merge: true }
          );
        }}
        label="Show Guests In Time Even On Large Floorplans"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.disableShiftCommentAtBottom}
        onChange={(_, disableShiftCommentAtBottom) => {
          experimentalFlags.ref.set(
            {
              disableShiftCommentAtBottom,
            },
            { merge: true }
          );
        }}
        label="Disable Shift Comment At Bottom"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.editGuestComment}
        onChange={(_, editGuestComment) => {
          experimentalFlags.ref.set(
            {
              editGuestComment,
            },
            { merge: true }
          );
        }}
        label="Edit Guest Comment"
      />

      <SwitchBox
        checked={!!experimentalFlags?.data?.hideTimeOfCurrentReservation}
        onChange={(_, hideTimeOfCurrentReservation) => {
          experimentalFlags.ref.set(
            {
              hideTimeOfCurrentReservation,
            },
            { merge: true }
          );
        }}
        label="Hide Time Of Current Reservation"
      />

      <TextField
        label="Country Code For Phone Numbers (de, at, etc.)"
        value={experimentalFlags?.data?.phoneDefaultCountry ?? 'de'}
        fullWidth
        onChange={(e) => {
          experimentalFlags.ref.set(
            {
              phoneDefaultCountry: e.target.value,
            },
            { merge: true }
          );
        }}
      />

      <TextField
        label="Sidebar Width"
        value={experimentalFlags?.data?.sidebarWidth ?? ''}
        fullWidth
        type="number"
        onChange={(e) => {
          experimentalFlags.ref.set(
            {
              sidebarWidth: e.target.value,
            },
            { merge: true }
          );
        }}
      />

      <TextField
        label="Font Size in Sidebar"
        value={experimentalFlags?.data?.sidebarFontSize ?? ''}
        fullWidth
        type="number"
        onChange={(e) => {
          experimentalFlags.ref.set(
            {
              sidebarFontSize: e.target.value,
            },
            { merge: true }
          );
        }}
      />

      <TextField
        label="Disabled Custom Fields"
        value={experimentalFlags?.data?.disabledCustomFields ?? ''}
        fullWidth
        onChange={(e) => {
          experimentalFlags.ref.set(
            {
              disabledCustomFields: e.target.value,
            },
            { merge: true }
          );
        }}
      />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h5" style={{ marginBottom: 10 }}>
          Tisch Status
        </Typography>

        <SwitchBox
          checked={!!experimentalFlags?.data?.allowMultipleStati}
          onChange={(_, allowMultipleStati) => {
            experimentalFlags.ref.set(
              {
                allowMultipleStati,
              },
              { merge: true }
            );
          }}
          label="Allow Multiple Stati"
        />

        {experimentalFlags?.data?.currentTableStati?.map((t, i, arr) => (
          <div
            key={i}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: 10,
              marginBottom: 10,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
              <IconButton
                size="small"
                disabled={!i}
                onClick={() => {
                  experimentalFlags.ref.set(
                    {
                      currentTableStati: changePositionInArray(arr, i, i - 1),
                    },
                    { merge: true }
                  );
                }}
              >
                <ArrowUpward />
              </IconButton>
              <IconButton
                size="small"
                disabled={arr.length - 1 === i}
                onClick={() => {
                  experimentalFlags.ref.set(
                    {
                      currentTableStati: changePositionInArray(arr, i, i + 1),
                    },
                    { merge: true }
                  );
                }}
              >
                <ArrowDownward />
              </IconButton>
            </div>
            <div style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 10,
                }}
              >
                <TextField
                  label="Emoji"
                  value={t.icon}
                  onChange={(e) => {
                    experimentalFlags.ref.set(
                      {
                        currentTableStati: arr.map((x, i1) =>
                          i1 === i ? { ...x, icon: e.target.value } : x
                        ),
                      },
                      { merge: true }
                    );
                  }}
                  style={{
                    width: 60,
                  }}
                />
                <TextField
                  label="Titel"
                  value={t.title}
                  style={{ flex: 1 }}
                  fullWidth
                  onChange={(e) => {
                    experimentalFlags.ref.set(
                      {
                        currentTableStati: arr.map((x, i1) =>
                          i1 === i ? { ...x, title: e.target.value } : x
                        ),
                      },
                      { merge: true }
                    );
                  }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 10,
                }}
              >
                <TextField
                  label="Tisch Farbe (+ Farbe der Reservierung)"
                  value={t.color}
                  fullWidth
                  helperText="Beispiel: #ffffff oder rgb(255,255,255)"
                  onChange={(e) => {
                    experimentalFlags.ref.set(
                      {
                        currentTableStati: arr.map((x, i1) =>
                          i1 === i ? { ...x, color: e.target.value } : x
                        ),
                      },
                      { merge: true }
                    );
                  }}
                />
                <TextField
                  label="Stuhl Farbe"
                  value={t.chairColor || t.color}
                  fullWidth
                  helperText="Beispiel: #ffffff oder rgb(255,255,255)"
                  onChange={(e) => {
                    experimentalFlags.ref.set(
                      {
                        currentTableStati: arr.map((x, i1) =>
                          i1 === i ? { ...x, chairColor: e.target.value } : x
                        ),
                      },
                      { merge: true }
                    );
                  }}
                />
              </div>
            </div>
            <div style={{ flexDirection: 'column', gap: 10 }}>
              <IconButton
                size="small"
                onClick={() => {
                  experimentalFlags.ref.set(
                    {
                      currentTableStati: arr.filter((x, i1) => i1 !== i),
                    },
                    { merge: true }
                  );
                }}
              >
                <Delete />
              </IconButton>
            </div>
          </div>
        ))}
        <Button
          onClick={() => {
            experimentalFlags.ref.set(
              {
                currentTableStati: FieldValue.arrayUnion({
                  title: '',
                  color: '',
                  chairColor: '',
                  icon: '',
                  textColor: '#ffffff',
                }),
              },
              { merge: true }
            );
          }}
        >
          Tischstatus hinzufügen
        </Button>
      </div>
    </Modal>
  );
};

export default ExperimentalFlags;
